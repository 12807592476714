<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-row justify="center">
        <v-col cols="8">
          <v-card class="rounded-lg" flat>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-select label="Sélectionnez une campagne *"/>
                </v-col>
                <v-col>
                  <v-select label="Sélectionnez un enquête *"/>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col class="text-center" cols="8">
                  <v-btn class="rounded-lg mb-3"
                         to="/analytics/results"
                         large color="primary" depressed>
                    <v-icon left>mdi-chart-bell-curve</v-icon>
                    Lancer les analyses
                  </v-btn>
                  <h2 class="text--primary my-4">Le Lorem Ipsum est simplement</h2>
                  <p>
                    Le Lorem Ipsum est simplement du faux texte employé dans
                  </p>
                </v-col>
                <v-col cols="12" class="text-center">
                  <img height="300" src="../../assets/Piechart.svg"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  data() {
    return {}
  },
  computed: {
    breadcrumbs() {
      return [
        {to: '/analytics', text: 'breadcrumb.analytics'},
      ]
    },
  }
};
</script>

